export const structuredEvent = (category, action, label, property, value, e) => {
  const isBrowser = typeof window !== 'undefined';
  if (e) {
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
  }
  isBrowser && window.snowplow && window.snowplow('trackStructEvent', category, action, label, property, value);
  return null;
};

export const getLocation = (props) => {
  let location = '';
  let { pathname, asPath } = props.router;

  if (!asPath.endsWith('/')) asPath += '/';
  if (!pathname.endsWith('/')) pathname += '/';

  // console.log(`getLocation -> asPath : ${asPath} `);
  // console.log(`getLocation -> pathname : ${pathname} `);

  if (pathname === '/news/article/') location = 'berita - detail berita';
  else if (asPath === '/berita/') location = 'homepage berita';
  else if (asPath === '/berita/utama/') location = 'berita - utama';
  else if (asPath === '/berita/terbaru/') location = 'berita - terbaru';
  else if (asPath === '/berita/kolom/') location = 'berita - kolom';
  else if (asPath === '/berita/foto/') location = 'berita - foto';
  else if (asPath === '/berita/jeda/') location = 'berita - jeda';
  else if (asPath === '/berita/tajuk/') location = 'berita - tajuk';
  else if (asPath === '/berita/profil/') location = 'berita - profil';
  else if (asPath === '/berita/isu-hangat/') location = 'berita - isu hangat';
  else if (asPath === '/berita/ini/') location = 'berita - pojok ini';
  else if (asPath === '/berita/akpi/') location = 'berita - pojok akpi';
  else if (asPath === '/berita/peradi/') location = 'berita - pojok peradi';
  else if (asPath === '/berita/pojok-kuhp/') location = 'berita - pojok kuhp';
  else if (asPath === '/berita/pojok-identitas-digital/') location = 'berita - pojok identitas digital';
  else if (asPath === '/berita/surat-pembaca/') location = 'berita - surat pembaca';
  else if (asPath === '/berita/kabar-kampus/') location = 'berita - kabar kampus';

  if (location === '') {
    if (pathname === '/clinic/newArticle/') location = 'new klinik - detail klinik';
    else if (asPath === '/klinik/') location = 'new klinik - homepage';
    else if (asPath === '/klinik/tips/') location = 'new klinik - tips hukum';
    else if (asPath === '/klinik/pidana/') location = 'new klinik - pidana';
    else if (asPath === '/klinik/keluarga/') location = 'new klinik - keluarga';
    else if (asPath === '/klinik/perdata/') location = 'new klinik - perdata';
    else if (asPath === '/klinik/kenegaraan/') location = 'new klinik - kenegaraan';
    else if (asPath === '/klinik/profesi-hukum/') location = 'new klinik - profesi hukum';
    else if (asPath === '/klinik/ilmu-hukum/') location = 'new klinik - ilmu hukum';
    else if (asPath === '/klinik/ketenagakerjaan/') location = 'new klinik - ketenagakerjaan';
    else if (asPath === '/klinik/pertanahan-properti/') location = 'new klinik - pertanahan dan properti';
    else if (asPath === '/klinik/perlindungan-konsumen/') location = 'new klinik - perlindungan konsumen';
    else if (asPath === '/klinik/english-edition/') location = 'new klinik - english edition';
    else if (asPath === '/klinik/ham/') location = 'new klinik - ham';
    else if (asPath === '/klinik/kekayaan-intelektual/') location = 'new klinik - kekayaan intelektual';
    else if (asPath === '/klinik/teknologi/') location = 'new klinik - teknologi';
    else if (asPath === '/klinik/start-up-umkm/') location = 'new klinik - startup dan umkm';
    else if (asPath === '/klinik/hak-asasi-manusia/') location = 'new klinik - ham';
    else if (asPath === '/klinik/bisnis/') location = 'new klinik - bisnis';
  }

  if (location === '') {
    if (pathname === '/event/detailEvent/') location = 'detail event';
    else if (asPath === '/talks/') location = 'homepage event';
    else if (asPath === '/talks/kegiatanterkini/') location = 'kegiatan terkini event';
    else if (asPath === '/talks/eo/') location = 'event organizer event';
    else if (asPath === '/talks/iht/') location = 'in house training event';
    else if (asPath === '/talks/arsipkegiatan/') location = 'arsip kegiatan event';
    else if (asPath === '/talks/narasumber/') location = 'narasumber event';
    else if (pathname === '/event/profileNarasumber/') location = 'detail narasumber';
  }

  if (location === '') {
    if (pathname === '/premium/article/') location = 'ps-detail artikel';
    else if (asPath === '/stories/') location = 'ps-home';
  }

  if ((location === '') && (asPath === '/')) {
    location = 'HOL - New Homepage';
  }

  return location;
};

export const structuredEventGenerator = (props) => {
  let trackerCategory;
  let trackerAction;
  let trackerLabel;
  let trackerProperty;
  let trackerValue;

  if (typeof (props.settingFeed.tracker) !== 'undefined' || props.settingFeed.tracker != null) {
    trackerCategory = props.settingFeed.tracker.category;
    trackerAction = props.settingFeed.tracker.action;

    if (props.settingFeed.tracker.label === ':guid') {
      trackerLabel = props.id || props.guid || '';
    } else {
      trackerLabel = props.settingFeed.tracker.label;
    }
    trackerProperty = props.settingFeed.tracker.property;

    if (props.settingFeed.tracker.value === ':index') {
      trackerValue = props.index + 1;
    } else {
      trackerValue = props.settingFeed.tracker.values;
    }

    const output = {
      canClick: true,
      category: trackerCategory,
      action: trackerAction,
      label: trackerLabel,
      property: trackerProperty,
      value: trackerValue,
    };
    return output;
  }

  const output = {
    canClick: false,
  };
  return output;
};

export const trackerMain = (props) => {
  if (props.snowplow !== undefined && props.snowplow !== '') {
    structuredEvent(props.snowplow[0], props.snowplow[1], props.snowplow[2], props.snowplow[3], props.snowplow[4], props.snowplow[5]);
  }
  if (props.ga4 !== undefined && props.ga4 !== '') {
    pushEventGA4(props.ga4);
  }

  return null;
};

/*    0              1           2            3           4       5            6       7
 typeGTMTriger, location,  eventName,parameterName,parameterValue,index,articleTitle,articleId,

 props[0]  typeGTMTriger - akan di mapping dengan GTM trigger , jenis / variabel tambahan yg akan di push ke GTM

 props[1] coming soon

 eventNameValue:props[2] => nama event yg akan tampil di FE analytics

*/
export const pushEventGA4 = (props) => {
  const isBrowser = typeof window !== 'undefined';

  // default event use parameter
  const myObj = {
    event: props[0],
    eventNameValue: props[2],
    parameterName: props[3],
    parameterValue: props[4],
  };

  if (props[0] == 'eventCoreV1Index') {
    myObj.event = 'eventCoreV1Dynamic1Parameter';
    myObj.parameter1Name = 'index';
    myObj.parameter1Value = props[5];
  } else if (props[0] == 'eventCoreV1Article') {
    myObj.event = 'eventCoreV1Dynamic3Parameter';
    myObj.parameter1Name = 'index';
    myObj.parameter1Value = props[5];
    myObj.parameter2Name = 'article_title';
    myObj.parameter2Value = props[6];
    myObj.parameter3Name = 'article_id';
    myObj.parameter3Value = props[7];
  } else if (props[0] == 'eventCoreV1ArticleFotoVideo') {
    if (props[5] === '') {
      myObj.event = 'eventCoreV1Dynamic2Parameter';
    } else {
      myObj.event = 'eventCoreV1Dynamic3Parameter';
      myObj.parameter1Name = 'index';
      myObj.parameter1Value = props[5];
    }
    myObj.parameter2Name = 'fotovideo_title';
    myObj.parameter2Value = props[6];
    myObj.parameter3Name = 'fotovideo_id';
    myObj.parameter3Value = props[7];
  } else if (props[0] == 'eventCoreV1Analysis') {
    myObj.event = 'eventCoreV1Dynamic3Parameter';
    myObj.parameter1Name = 'index';
    myObj.parameter1Value = props[5];
    myObj.parameter2Name = 'analysis_title';
    myObj.parameter2Value = props[6];
    myObj.parameter3Name = 'analysis_id';
    myObj.parameter3Value = props[7];
  } else if (props[0] == 'eventCoreV1Course') {
    myObj.event = 'eventCoreV1Dynamic3Parameter';
    myObj.parameter1Name = 'index';
    myObj.parameter1Value = props[5];
    myObj.parameter2Name = 'course_title';
    myObj.parameter2Value = props[6];
    myObj.parameter3Name = 'course_id';
    myObj.parameter3Value = props[7];
  } else if (props[0] == 'eventCoreV1Regulation') {
    myObj.event = 'eventCoreV1Dynamic3Parameter';
    myObj.parameter1Name = 'index';
    myObj.parameter1Value = props[5];
    myObj.parameter2Name = 'regulation_title';
    myObj.parameter2Value = props[6];
    myObj.parameter3Name = 'regulation_id';
    myObj.parameter3Value = props[7];
  } else if (props[0] == 'eventCoreV1Decision') {
    myObj.event = 'eventCoreV1Dynamic3Parameter';
    myObj.parameter1Name = 'index';
    myObj.parameter1Value = props[5];
    myObj.parameter2Name = 'decision_title';
    myObj.parameter2Value = props[6];
    myObj.parameter3Name = 'decision_id';
    myObj.parameter3Value = props[7];
  } else if (props[0] == 'eventCoreV1Translation') {
    myObj.event = 'eventCoreV1Dynamic3Parameter';
    myObj.parameter1Name = 'index';
    myObj.parameter1Value = props[5];
    myObj.parameter2Name = 'translation_title';
    myObj.parameter2Value = props[6];
    myObj.parameter3Name = 'translation_id';
    myObj.parameter3Value = props[7];
  } else if (props[0] == 'eventCoreV1Clinic') {
    myObj.event = 'eventCoreV1Dynamic3Parameter';
    myObj.parameter1Name = 'index';
    myObj.parameter1Value = props[5];
    myObj.parameter2Name = 'clinic_title';
    myObj.parameter2Value = props[6];
    myObj.parameter3Name = 'clinic_id';
    myObj.parameter3Value = props[7];
  } else if (props[0] == 'eventCoreV1Infografic') {
    if (props[7] === '') {
      myObj.event = 'eventCoreV1Dynamic1Parameter';
      myObj.parameter1Name = 'index';
      myObj.parameter1Value = props[5];
    } else {
      myObj.event = 'eventCoreV1Dynamic3Parameter';
      myObj.parameter1Name = 'index';
      myObj.parameter1Value = props[5];
      myObj.parameter2Name = 'infographic_title';
      myObj.parameter2Value = props[6];
      myObj.parameter3Name = 'infographic_id';
      myObj.parameter3Value = props[7];
    }
  } else if (props[0] == 'eventCoreV1Event') {
    myObj.event = 'eventCoreV1Dynamic3Parameter';
    myObj.parameter1Name = 'index';
    myObj.parameter1Value = props[5];
    myObj.parameter2Name = 'event_title';
    myObj.parameter2Value = props[6];
    myObj.parameter3Name = 'event_id';
    myObj.parameter3Value = props[7];
  } else if (props[0] == 'eventCoreV1Stories') {
    myObj.event = 'eventCoreV1Dynamic3Parameter';
    myObj.parameter1Name = 'index';
    myObj.parameter1Value = props[5];
    myObj.parameter2Name = 'stories_title';
    myObj.parameter2Value = props[6];
    myObj.parameter3Name = 'stories_id';
    myObj.parameter3Value = props[7];
  } else if (props[0] == 'eventCoreV1StoriesAuthor') {
    myObj.event = 'eventCoreV1Dynamic3Parameter';
    myObj.parameter1Name = 'index';
    myObj.parameter1Value = props[5];
    myObj.parameter2Name = 'storiesauthor_title';
    myObj.parameter2Value = props[6];
    myObj.parameter3Name = 'storiesauthor_id';
    myObj.parameter3Value = props[7];
  }

  // console.log("ga4::182A");
  // console.table(myObj);

  isBrowser
        && window.dataLayer
        && window.dataLayer.push(myObj);

  return null;
};

export function pushGa4Generic({ event, action, component }) {
  const isBrowser = typeof window !== 'undefined';

  // default event use parameter
  const myObj = {
    event: event.toLowerCase(),
    parameterName: action,
    parameterValue: component.toLowerCase(),
  };

  isBrowser
        && window.dataLayer
        && window.dataLayer.push(myObj);
}

export function pushSingleDataLayer({ eventName, eventValue }) {
  const isBrowser = typeof window !== 'undefined';

  const myObj = {
    [eventName]: eventValue,
  };

  isBrowser
        && window.dataLayer
        && window.dataLayer.push(myObj);
}
