import React from 'react';
import { MdLocationPin, MdMail, MdPhone } from 'react-icons/md';
import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsTwitter,
  BsYoutube,
} from 'react-icons/bs';
import { structuredEvent, pushGa4Generic } from '../../libraries/tracker';
// import facebookLogo from '../../static/images/social/facebook.svg';
// import instagramLogo from '../../static/images/social/instagram.svg';
// import linkedinLogo from '../../static/images/social/linkedin.svg';
// import youtubeLogo from '../../static/images/social/youtube.svg';
// import twitterLogo from '../../static/images/social/X.svg';
// import tiktokLogo from '../../static/images/social/tiktok.svg';

const getConfig = require('next/config').default;

const { publicRuntimeConfig } = getConfig();
const { BASE_URL, JURNAL_URL, PRO_URL, OC_URL } = publicRuntimeConfig;

const facebookLogo = `${BASE_URL}/static/images/social/facebook.svg`;
const instagramLogo = `${BASE_URL}/static/images/social/instagram.svg`;
const linkedinLogo = `${BASE_URL}/static/images/social/linkedin.svg`;
const youtubeLogo = `${BASE_URL}/static/images/social/youtube.svg`;
const twitterLogo = `${BASE_URL}/static/images/social/X.svg`;
const tiktokLogo = `${BASE_URL}/static/images/social/tiktok.svg`;

const MobileFooter = ({
  t,
  logo,
  textColor,
  primaryTextColor,
  logoKominfo,
  bgColor,
}) => (
  <footer
    className={`d-flex flex-column justify-content-between container ${bgColor} py-4 px-4 pb-5`}
  >
    <div className="">
      <img src={logo} alt="Logo Hukumonline" className="footer-logo" />
      <div className="pt-3 social-icon d-flex flex-row">
        <a
          onClick={() => {
            structuredEvent(
              'footer',
              'click url - facebook',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'facebook',
            });
          }}
          className={`${textColor} small`}
          href="https://www.facebook.com/hukumonlinecom/"
        >
          <img
            alt="facebook-hukumonline"
            src={facebookLogo}
            width={24}
            height={24}
          />
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'footer',
              'click url - instagram',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'instagram',
            });
          }}
          className={`${textColor} small`}
          href="https://www.instagram.com/hukum_online/"
        >
          <img
            alt="instagram-hukumonline"
            src={instagramLogo}
            width={24}
            height={24}
          />
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'footer',
              'click url - linkedin',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'linkedin',
            });
          }}
          className={`${textColor} small`}
          href="https://www.linkedin.com/company/hukumonline.com/"
        >
          <img
            alt="linkedin-hukumonline"
            src={linkedinLogo}
            width={24}
            height={24}
          />
        </a>
        <a
          onClick={() => {
            structuredEvent('footer', 'click url - youtube', 'beranda', '', '');
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'youtube',
            });
          }}
          className={`${textColor} small`}
          href="https://www.youtube.com/user/hukumonlinevideo"
        >
          <img
            alt="youtube-hukumonline"
            src={youtubeLogo}
            width={24}
            height={24}
          />
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'footer',
              'click url - twitter ',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'twitter',
            });
          }}
          className={`${textColor} small`}
          href="https://www.twitter.com/hukumonline"
        >
          <img
            alt="twitter-hukumonline"
            src={twitterLogo}
            width={24}
            height={24}
          />
        </a>
        <a
          onClick={() => {
            structuredEvent('footer', 'click url - tiktok', 'beranda', '', '');
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'tiktok',
            });
          }}
          className={`${textColor} small rounded`}
          href="https://www.tiktok.com/@hukumonline"
        >
          <img
            alt="tiktok-hukumonline"
            src={tiktokLogo}
            width={24}
            height={24}
          />
        </a>
      </div>
      <div className="footer-address">
        <address className="footer-address-content mb-0">
          <div>
            <MdLocationPin fontSize="16px" className={textColor} />
          </div>

          <span className={`${textColor}`}>
            AD Premier 9th floor, Jl. TB Simatupang No.5 <br />
            Ragunan, Pasar Minggu, Jakarta Selatan 12550, <br />
            DKI Jakarta, Indonesia
          </span>
        </address>
        <div className="footer-address-content mb-0">
          <MdPhone fontSize="16px" className={` ${textColor}`} />
          <div className="">
            <a className={`${textColor}`} href="tel:+622122708910">
              Phone: +62 21 - 2270 - 8910
            </a>
            <br />
            <a className={`${textColor}`} href="fax:+622122708909">
              Fax: +62 21 - 2270 - 8909
            </a>
          </div>
        </div>
        <div className="footer-address-content mb-0">
          <MdMail fontSize="16px" className={`${textColor}`} />
          <div className="">
            <a
              className={`${textColor}`}
              href="mailto:customer@hukumonline.com"
            >
              customer@hukumonline.com
            </a>
            <br />
            <a className={`${textColor}`} href="mailto:redaksi@hukumonline.com">
              redaksi@hukumonline.com
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="d-flex flex-row justify-content-between py-4 footer-wrapper">
      <div className="footer-section">
        <div className="d-flex flex-column">
          <span className={`footer-content-title ${primaryTextColor}`}>
            Pro
          </span>
          <div className="footer-content mt-1">
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - analisis_hukum',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'analisis_hukum',
                });
              }}
              className={`${textColor}`}
              href={`${PRO_URL}/?utm_source=hukumonline&utm_medium=footer`}
            >
              Analisis Hukum
            </a>
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - pusat_data',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'pusat_data',
                });
              }}
              className={`${textColor}`}
              href={`${BASE_URL}/pusatdata?utm_source=website&utm_medium=footer`}
            >
              {t('pusat data')}
            </a>
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - legal_intelligence_updates',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'legal_intelligence_updates',
                });
              }}
              className={`${textColor}`}
              href={`${PRO_URL}/legal-intelligence/?utm_source=website&utm_medium=footer`}
            >
              Legal Intelligence Updates
            </a>
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - premium_stories',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'premium_stories',
                });
              }}
              className={`${textColor}`}
              href={`${BASE_URL}/stories/?utm_source=website&utm_medium=footer`}
            >
              Premium Stories
            </a>
          </div>
        </div>
        <div className="d-flex flex-column">
          <span className={`footer-content-title ${primaryTextColor}`}>
            Solusi
          </span>
          <div className="footer-content mt-1">
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - unisol',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'unisol',
                });
              }}
              className={`${textColor}`}
              href={`${PRO_URL}/solusi-universitas-hukum/?utm_source=website&utm_medium=footer`}
            >
              University Solutions
            </a>
            <a
              onClick={() => {
                structuredEvent('footer', 'click url - rcs', 'beranda', '', '');
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'rcs',
                });
              }}
              className={`${textColor}`}
              href="https://rcs.hukumonline.com/?utm_source=website&utm_medium=footer"
            >
              Regulatory Compliance System
            </a>
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - exdoma',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'exdoma',
                });
              }}
              className={`${textColor}`}
              href="https://exdoma.hukumonline.com/?utm_source=website&utm_medium=footer"
            >
              Document Management System
            </a>
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - easybiz',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'easybiz',
                });
              }}
              className={`${textColor}`}
              href="https://www.easybiz.id/#layanan-dan-harga?utm_source=hukumonline&utm_medium=footer"
            >
              Izin Usaha
            </a>
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - konsultasi_hukum',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'konsultasi_hukum',
                });
              }}
              className={`${textColor}`}
              href="https://www.justika.com/partner/klinik/?utm_source=hukumonline&utm_medium=footer&utm_campaign=chat"
            >
              Konsultasi Hukum
            </a>
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - pembuatan_dokumen',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'pembuatan_dokumen',
                });
              }}
              className={`${textColor}`}
              href="https://business.justika.com/template.html?utm_source=hukumonline&utm_medium=navbar&utm_campaign=template"
            >
              Pembuatan Dokumen
            </a>
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - hol360',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'hol360',
                });
              }}
              className={`${textColor}`}
              href="https://www.hukumonline.com/hukumonline-360/"
            >
              Hukumonline 360
            </a>
          </div>
        </div>
        <div className="d-flex flex-column mb-4">
          <span className={`footer-content-title ${primaryTextColor}`}>
            Hukumonline
          </span>
          <div className="footer-content mt-1">
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - tentang_kami',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'tentang_kami',
                });
              }}
              className={`${textColor}`}
              href={`${BASE_URL}/tentangkami`}
            >
              {t('tentang kami')}
            </a>
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - katalog_produk',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'katalog_produk',
                });
              }}
              className={`${textColor}`}
              href={`${BASE_URL}/produk/id/?utm_source=website&utm_medium=footer`}
            >
              {t('katalog produk')}
            </a>
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - redaksi',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'redaksi',
                });
              }}
              className={`${textColor}`}
              href={`${BASE_URL}/redaksi`}
            >
              {t('redaksi')}
            </a>
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - pedoman_media_siber',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'pedoman_media_siber',
                });
              }}
              className={`${textColor}`}
              href={`${BASE_URL}/pedoman-berita`}
            >
              {t('pedoman media siber')}
            </a>
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - kode_etik',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'kode_etik',
                });
              }}
              className={`${textColor}`}
              href={`${BASE_URL}/kode-etik`}
            >
              {t('kode etik')}
            </a>
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - syarat_penggunaan_layanan',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'syarat_penggunaan_layanan',
                });
              }}
              className={`${textColor}`}
              href={`${BASE_URL}/syarat-penggunaan-layanan`}
            >
              {t('syarat pengunaan layanan')}
            </a>
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - bantuan_faq',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'bantuan_faq',
                });
              }}
              className={`${textColor}`}
              href={`${BASE_URL}/helpcenter`}
            >
              {t('bantuan')}
            </a>
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - karir',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'karir',
                });
              }}
              className={`${textColor}`}
              href={`${BASE_URL}/karir`}
            >
              {t('karir')}
            </a>
          </div>
        </div>
      </div>
      <div className="footer-section">
        <div className="">
          <span className={`footer-content-title ${primaryTextColor}`}>
            Info Hukum
          </span>
          <div className="footer-content mt-1">
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - klinik',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'klinik',
                });
              }}
              className={`${textColor}`}
              href="https://www.hukumonline.com/klinik/?utm_source=hukumonline&utm_medium=footer"
            >
              Klinik
            </a>
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - hukumonline_stream',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'hukumonline_stream',
                });
              }}
              className={`${textColor}`}
              href="https://www.hukumonline.com/stream/?utm_source=hukumonline&utm_medium=footer"
            >
              Hukumonline Stream
            </a>
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - jurnal',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'jurnal',
                });
              }}
              className={`${textColor}`}
              href="https://jurnal.hukumonline.com/?utm_source=website&utm_medium=footer"
            >
              Jurnal
            </a>
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - berita',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'berita',
                });
              }}
              className={`${textColor}`}
              href="https://www.hukumonline.com/berita/?utm_source=website&utm_medium=footer"
            >
              Berita
            </a>
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - datapribadi',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'data pribadi',
                });
              }}
              className={`${textColor}`}
              href="https://www.datapribadi.id/?utm_source=hukumonline&utm_medium=footer"
            >
              Data Pribadi
            </a>
          </div>
        </div>
        <div className="">
          <span className={`footer-content-title ${primaryTextColor}`}>
            Event & Awards
          </span>
          <div className="footer-content mt-1">
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - event',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'event',
                });
              }}
              className={`${textColor}`}
              href="https://www.hukumonline.com/events/"
            >
              Event
            </a>
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - awards',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'awards',
                });
              }}
              className={`${textColor}`}
              href="https://awards.hukumonline.com/?utm_source=website&utm_medium=footer"
            >
              Awards
            </a>
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - online_publication',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'online_publication',
                });
              }}
              className={`${textColor}`}
              href="https://www.hukumonline.com/online-publication/"
            >
              Publikasi Online
            </a>
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - online_course',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'online course',
                });
              }}
              className={`${textColor}`}
              href="https://learning.hukumonline.com/?utm_source=website&utm_medium=footer"
            >
              Online Course
            </a>
            <a
              onClick={() => {
                structuredEvent(
                  'footer',
                  'click url - pkpa',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'footer',
                  action: 'click url',
                  component: 'pkpa',
                });
              }}
              className={`${textColor}`}
              href="https://pkpa.hukumonline.com/?utm_source=website&utm_medium=footer"
            >
              PKPA
            </a>
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-12 mb-3 d-flex justify-content-center">
            <img
              src="https://static.hukumonline.com/frontend/images/logo_BVCER_SansQR-ISO_27001_9001.png"
              alt="ISO_27001_9001"
              title="ISO_27001_9001"
              className="isoLinks-mobile"
            />
          </div>
          <div className="col-6 mb-3 d-flex justify-content-center">
            <img
              src="https://static.hukumonline.com/frontend/images/logo_KAN.png"
              alt="KAN"
              title="KAN"
              className="isoLinks-mobile"
            />
          </div>
          <div className="col-6 mb-3 d-flex justify-content-center">
            <img
              src="https://static.hukumonline.com/frontend/images/logo_ISO_9K_UKAS.png"
              alt="ISO 9k UKAS"
              title="ISO 9k UKAS"
              className="isoLinks-mobile"
            />
          </div>
          <div className="col-6 mb-3 d-flex justify-content-center">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://pse.kominfo.go.id/tdpse-detail/5020"
            >
              <img src={logoKominfo} alt="logo kominfo" className="isoLinks-mobile" />
            </a>
          </div>
          <div className="col-6 mb-3 d-flex justify-content-center">
            <img
              src={`${BASE_URL}/static/images/logo/trusted.png`}
              alt="logo trusted"
              className="isoLinks-mobile"
            />
          </div>
        </div>
      </div>
    </div>
  </footer>
);
export default MobileFooter;
