const axios = require('axios');
const getConfig = require('next/config').default;

// retrieve configuration
const { publicRuntimeConfig } = getConfig();
const { API_BASE_URL, API_BASE_URL_MOCK } = publicRuntimeConfig;

// prepare endpoint url string
const getUrl = endpoint => API_BASE_URL + endpoint;
const getUrlMock = endpoint => API_BASE_URL_MOCK + endpoint;

const post = async (endpoint, data, token) => axios.post(getUrl(endpoint), data, {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});
const put = async (endpoint, data, token) => axios.put(getUrl(endpoint), data, {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${token}`,
  },
});

const postData = async (endpoint, data, token) => {
  try {
    const res = await post(endpoint, data, token);
    return res.data;
  } catch (error) {
    return error.response;
  }
};
const putData = async (endpoint, data, token) => {
  try {
    const res = await put(endpoint, data, token);
    return res.data;
  } catch (error) {
    return error.response;
  }
};

const get = async (endpoint, token) => {
  const headers = token
    ? {
      headers: { Authorization: `Bearer ${token}` },
    }
    : null;
  return axios.get(getUrl(endpoint), headers);
};
const getWp = async (endpoint, token) => {
  const headers = token
    ? {
      headers: { Authorization: `Bearer ${token}` },
    }
    : null;

  try {
    const res = await axios.get(endpoint, headers);
    return res.data;
  } catch (error) {
    return error.response;
  }
};

const getData = async (endpoint, token) => {
  try {
    const res = await get(endpoint, token);
    return res.data;
  } catch (error) {
    const res = ({
      status: error.response && error.response.status ? error.response.status : 'unknown',
      message: error.message,
    });
    return res;
  }
};


const postEmail = (endpoint, data, token) => new Promise((resolve, reject) => {
  postData(endpoint, {
    ...data,
  }, token)
    .then(res => resolve(res.data))
    .catch(err => reject(err));
});


module.exports = {
  get,
  post,
  getData,
  putData,
  postData,
  postEmail,
  getWp,
};
