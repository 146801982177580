import React from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';

import { removeCookie } from '../../libraries/session';

const chevronPressed = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0967 7.64343C10.0683 7.71195 10.0203 7.77053 9.95862 7.81175C9.89696 7.85297 9.82446 7.87497 9.7503 7.87499H2.2503C2.17608 7.87505 2.10352 7.85309 2.0418 7.81188C1.98008 7.77068 1.93197 7.71209 1.90356 7.64354C1.87515 7.57498 1.86773 7.49953 1.88222 7.42675C1.89672 7.35397 1.93248 7.28712 1.98498 7.23468L5.73498 3.48468C5.76981 3.44981 5.81117 3.42215 5.85669 3.40328C5.90222 3.38441 5.95101 3.37469 6.0003 3.37469C6.04958 3.37469 6.09837 3.38441 6.1439 3.40328C6.18942 3.42215 6.23078 3.44981 6.26561 3.48468L10.0156 7.23468C10.068 7.28715 10.1037 7.35399 10.1182 7.42675C10.1326 7.49951 10.1251 7.57491 10.0967 7.64343Z"
      fill="#A8B8C7"
    />
  </svg>
);

export const LanguageDropdown = (props) => {
  const { i18n } = props;
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleLanguageChange = (language) => {
    document.cookie.split('; ').forEach((item) => {
      if (item.startsWith('lang=')) {
        removeCookie('lang');
      }
    });
    i18n.changeLanguage(language);
  };

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      className={`position-relative lang-dropdown ml-2 ${
        dropdownOpen ? 'active' : ''
      }`}
    >
      <DropdownToggle className="text-uppercase text-white font-weight-medium bg-transparent border-0 d-flex align-items-center">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mr-2"
        >
          <path
            d="M8 1.5C6.71442 1.5 5.45772 1.88122 4.3888 2.59545C3.31988 3.30968 2.48676 4.32484 1.99479 5.51256C1.50282 6.70028 1.37409 8.00721 1.6249 9.26809C1.8757 10.529 2.49477 11.6872 3.40381 12.5962C4.31285 13.5052 5.47104 14.1243 6.73192 14.3751C7.99279 14.6259 9.29973 14.4972 10.4874 14.0052C11.6752 13.5132 12.6903 12.6801 13.4046 11.6112C14.1188 10.5423 14.5 9.28558 14.5 8C14.4982 6.27665 13.8128 4.62441 12.5942 3.40582C11.3756 2.18722 9.72335 1.50182 8 1.5ZM6.35188 10.5H9.64813C9.3125 11.6462 8.75 12.6794 8 13.4931C7.25 12.6794 6.6875 11.6462 6.35188 10.5ZM6.125 9.5C5.95917 8.50688 5.95917 7.49312 6.125 6.5H9.875C10.0408 7.49312 10.0408 8.50688 9.875 9.5H6.125ZM2.5 8C2.49957 7.49277 2.56961 6.98795 2.70813 6.5H5.11188C4.96271 7.49444 4.96271 8.50556 5.11188 9.5H2.70813C2.56961 9.01205 2.49957 8.50723 2.5 8ZM9.64813 5.5H6.35188C6.6875 4.35375 7.25 3.32063 8 2.50688C8.75 3.32063 9.3125 4.35375 9.64813 5.5ZM10.8881 6.5H13.2919C13.5694 7.48075 13.5694 8.51925 13.2919 9.5H10.8881C11.0373 8.50556 11.0373 7.49444 10.8881 6.5ZM12.8981 5.5H10.6838C10.4286 4.49594 10.0001 3.54415 9.4175 2.6875C10.1618 2.88751 10.856 3.24108 11.4554 3.72546C12.0549 4.20984 12.5463 4.81431 12.8981 5.5ZM6.5825 2.6875C5.99994 3.54415 5.57142 4.49594 5.31625 5.5H3.10188C3.45371 4.81431 3.94515 4.20984 4.54459 3.72546C5.14404 3.24108 5.83822 2.88751 6.5825 2.6875ZM3.10188 10.5H5.31625C5.57142 11.5041 5.99994 12.4558 6.5825 13.3125C5.83822 13.1125 5.14404 12.7589 4.54459 12.2745C3.94515 11.7902 3.45371 11.1857 3.10188 10.5ZM9.4175 13.3125C10.0001 12.4558 10.4286 11.5041 10.6838 10.5H12.8981C12.5463 11.1857 12.0549 11.7902 11.4554 12.2745C10.856 12.7589 10.1618 13.1125 9.4175 13.3125Z"
            fill="#A8B8C7"
          />
        </svg>

        <span className="mr-1 active-lang">{i18n.language}</span>
        {dropdownOpen ? (
          chevronPressed
        ) : (
          <svg
            width="10"
            height="5"
            viewBox="0 0 10 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="chevron-down ml-1"
          >
            <path
              d="M9.01561 0.765313L5.26561 4.51531C5.23078 4.55018 5.18942 4.57784 5.1439 4.59671C5.09837 4.61558 5.04958 4.62529 5.0003 4.62529C4.95101 4.62529 4.90222 4.61558 4.85669 4.59671C4.81117 4.57784 4.76981 4.55018 4.73498 4.51531L0.984983 0.765313C0.932479 0.712867 0.896716 0.646022 0.882222 0.57324C0.867728 0.500458 0.875155 0.425012 0.903562 0.356454C0.931969 0.287895 0.980079 0.229307 1.0418 0.188105C1.10352 0.146903 1.17608 0.124942 1.2503 0.125H8.7503C8.82451 0.124942 8.89707 0.146903 8.95879 0.188105C9.02051 0.229307 9.06862 0.287895 9.09703 0.356454C9.12544 0.425012 9.13286 0.500458 9.11837 0.57324C9.10388 0.646022 9.06811 0.712867 9.01561 0.765313Z"
              fill="currentColor"
            />
          </svg>
        )}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          disabled
          onClick={() => handleLanguageChange('id')}
          className="option-lang selected"
        >
          {i18n.language === 'en' ? 'English' : 'Indonesia'}
        </DropdownItem>
        <div className="px-3">
          <hr className="m-0" />
        </div>
        <DropdownItem
          onClick={() =>
            handleLanguageChange(i18n.language === 'en' ? 'id' : 'en')
          }
          className="option-lang $"
        >
          <span>{i18n.language === 'en' ? 'Indonesia' : 'English'}</span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
