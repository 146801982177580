import cookie from 'js-cookie';

export const setCookie = (key, value) => {
  if (process.browser) {
    cookie.set(key, value, {
      expires: 1,
      path: '/',
      domain: '.hukumonline.com',
    });
  }
};

export const setCookieWithExpire = (key, value, expire) => {
  if (process.browser) {
    cookie.set(key, value, {
      expires: expire,
      path: '/',
      domain: '.hukumonline.com',
    });
  }
};

export const removeCookie = (key) => {
  if (process.browser) {
    cookie.remove(key, {
      expires: 1,
      path: '/',
    });
  }
};

export const getCookie = (key, req) =>
  process.browser ? getCookieFromBrowser(key) : getCookieFromServer(key, req);

const getCookieFromBrowser = (key) => cookie.get(key);

const getCookieFromServer = (key, req) => {
  if (!req || !req.headers || !req.headers.cookie) {
    return undefined;
  }
  const rawCookie = req.headers.cookie
    .split(';')
    .find((c) => c.trim().startsWith(`${key}=`));
  if (!rawCookie) {
    return undefined;
  }
  return rawCookie.split('=')[1];
};

export const getCookieJSON = (key, req) =>
  process.browser
    ? getCookieJSONFromBrowser(key)
    : getCookieJSONFromServer(key, req);

const getCookieJSONFromBrowser = (key) => cookie.getJSON(key);

const getCookieJSONFromServer = (key, req) => {
  if (!req || !req.headers || !req.headers.cookie) {
    return undefined;
  }
  const rawCookie = req.headers.cookie
    .split(';')
    .find((c) => c.trim().startsWith(`${key}=`));
  if (!rawCookie) {
    return undefined;
  }
  return rawCookie.split('=')[1];
};
