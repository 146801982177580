import React, { useRef, useState, useContext } from 'react';
import Container from 'reactstrap/lib/Container';
import {
  BsFillStarFill,
  BsChevronDown,
  BsSearch,
  BsBoxArrowInRight,
} from 'react-icons/bs';
import { AiOutlineCheck } from 'react-icons/ai';
import dynamic from 'next/dynamic';
import Router, { useRouter } from 'next/router';
import {
  FaChevronRight,
  FaChevronLeft,
  FaChevronDown,
  FaChevronUp,
} from 'react-icons/fa';
import { isPaidMember } from '../../libraries/memberCheck';
import { pushGa4Generic, structuredEvent } from '../../libraries/tracker';

import DesktopPro from './menu/desktopPro';
import DesktopSolusi from './menu/desktopSolusi';
import DesktopWawasan from './menu/desktopWawasan';
import DesktopEvent from './menu/desktopEvent';

import { NavbarContext } from '.';
import ButtonWorkspace from './buttonWorkspace';
import { encodeBase64 } from '../../libraries/utils';
import { LanguageDropdown } from './LanguageDropdown';
import { ButtonNotification } from './buttonNotification';

const DesktopSearch = dynamic(() => import('./menu/desktopSearch'), {
  ssr: false,
});
const DesktopSearchSuggest = dynamic(
  () => import('./menu/desktopSearchSuggest'),
  {
    ssr: false,
  },
);

const getConfig = require('next/config').default;

const { publicRuntimeConfig } = getConfig();
const { BASE_URL, SEARCH_URL, IDENTITY_URL, OC_URL } = publicRuntimeConfig;

const menuState = {
  isOpen: false,
  isSearchActive: false,
  menuActive: '',
};
const sidebarState = {
  isOpen: {
    lvl1: false,
    lvl2: false,
    lvl3: false,
  },
  menuActiveLvl1: '',
  menuActiveLvl2: '',
  menuActiveLvl3: '',
};
const toggleMenu = (data, setMenu) => {
  setMenu(data);
};
const ProfileComponent = ({
  userDetail,
  returnURL: returnURLProps,
  menuType,
  i18n,
  t,
}) => {
  const [menu, setMenu] = useState(sidebarState);

  const router = useRouter();
  const returnURL =
    router.pathname === '/landing'
      ? encodeBase64('https://pro.hukumonline.com/workspace/')
      : returnURLProps;

  const beforeLogin = (
    <div className="d-flex flex-shrink-0 ">
      <div className="d-flex align-items-center px-3">
        <button
          type="button"
          title="daftar"
          name="daftar"
          onClick={async () => {
            const tracker = (
              await import('../../libraries/tracker')
            ).structuredEvent('navbar', 'click url  - daftar', 'beranda', '');
            tracker;

            router.push(
              `${IDENTITY_URL}/user/signup?utm_source=website&utm_medium=header`,
            );
          }}
        >
          <span className="body font-weight-semibold text-white mr-1">
            Daftar
          </span>
        </button>
      </div>
      <div className="d-flex align-items-center px-3">
        <button
          type="button"
          title="masuk"
          name="masuk"
          onClick={async () => {
            const tracker = (
              await import('../../libraries/tracker')
            ).structuredEvent('navbar', 'click url - login', 'beranda', '');
            const pustGA4 = (
              await import('../../libraries/tracker')
            ).pushGa4Generic({
              event: 'navbar',
              action: 'click url',
              component: 'login',
            });
            tracker;
            pustGA4;

            router.push(
              `${IDENTITY_URL}/user/login?returnUrl=${returnURL}&utm_source=website&utm_medium=header`,
            );
          }}
        >
          <span className="body font-weight-semibold text-white mr-1">
            Masuk
          </span>
          <BsBoxArrowInRight fontSize="16px" className="text-white ml-1" />{' '}
        </button>
      </div>
    </div>
  );
  const afterLogin = (
    <>
      {menuType === 'pro' && (
        <div className="mr-2">
          <LanguageDropdown i18n={i18n} t={t} />
        </div>
      )}
      <ButtonNotification />

      <ButtonWorkspace />
    </>
  );
  return userDetail.isAuthenticated ? afterLogin : beforeLogin;
};

export const searchOption = [
  'Semua',
  'Analisis',
  'Peraturan',
  'Putusan',
  'Legal Intelligence',
  'Berita',
  'Klinik',
  'Events',
];

const MenuDesktop = React.memo((props) => {
  const valueSearch =
    (props.router && props.router.query && props.router.query.q) || '';
  const [formSearch, setFormSearch] = useState(valueSearch);
  const [currentSearchOptions, setCurrentSearchOptions] = useState('Semua');
  const searchInput = useRef(null);
  const { searchSuggestions } = useContext(NavbarContext);

  const handleChange = (e) => {
    setFormSearch(e.target.value);
    handleSearchSuggest(e);
  };

  const handleSearchSuggest = (e) => {
    if (e.target.value.length > 0) {
      setMenu({
        ...menu,
        isOpen: true,
        menuActive: 'SearchSuggest',
        isSearchActive: true,
      });
    } else {
      setMenu({
        ...menu,
        isOpen: true,
        menuActive: 'Search',
        isSearchActive: true,
      });
    }
  };

  const handleSubmit = ({
    isSuggestions = false,
    selectedSuggestions = '',
    index = 0,
  } = {}) => {
    const mapSearchOption = {
      Semua: 'all',
      Analisis: 'analyses',
      Peraturan: 'regulations',
      Putusan: 'decisions',
      Berita: 'news',
      Klinik: 'clinics',
      Events: 'events',
    };
    // p=0&q=uu+cipta+kerja&l=10&o=desc&s=relevancy&language=&year=&input=uu&pos=0&recoms=12

    const params = {
      p: 0,
      q: isSuggestions ? selectedSuggestions : formSearch,
      l: 10,
      o: 'desc',
      s: 'relevancy',
      language: '',
      year: '',
      input: formSearch,
      pos: index,
      recoms: searchSuggestions.length,
    };
    structuredEvent(
      'navbar_search_click',
      'button - button search navbar',
      '',
      '',
    );
    pushGa4Generic({
      event: 'navbar_search_click',
      action: 'button',
      component: 'button search navbar',
    });

    Router.push({
      pathname: `${SEARCH_URL}/search/${mapSearchOption[currentSearchOptions]}/`,
      query: params,
    });
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };
  const [menu, setMenu] = useState(menuState);
  const logoHolpro = {
    src: '/static/images/pro/hol-pro-white.png',
    href: 'https://pro.hukumonline.com/?utm_source=website&utm_medium=header',
  };
  const logoHol = {
    src: 'https://images.hukumonline.com/website-assets/navbar/logo-hol.png',
    href: 'https://www.hukumonline.com',
  };
  const logo = isPaidMember(props.userDetail.groups) ? logoHolpro : logoHol;
  const labelSemuaFilterSearch = props.t('all');
  return (
    <>
      <Container className="d-flex justify-content-between">
        <div className="d-flex align-items-center mr-4">
          <div>
            <a href={BASE_URL}>
              <img
                src={logo.src}
                alt="Logo Hukumonline"
                className="main-logo"
              />
            </a>
          </div>
        </div>
        <div
          className="d-flex align-items-center justify-content-between flex-md-grow-1 position-relative"
          id="desktop"
        >
          <div
            className={`d-flex flex-grow-1 ${
              menu.isSearchActive
                ? 'position-relative align-items-center mr-3'
                : ''
            }`}
          >
            <div
              className={`input-group search-section mr-2 ${
                menu.isSearchActive ? 'expand position-absolute' : ''
              }`}
            >
              <input
                ref={searchInput}
                type="text"
                className={`form-control search-input-h   py-2 ${
                  menu.isSearchActive ? 'active' : ''
                }`}
                placeholder={props.t('Placeholder Search')}
                aria-label="Cari peraturan, artikel, dan lainnya..."
                onChange={handleChange}
                onFocus={(e) => {
                  setMenu({
                    ...menu,
                    isOpen: true,
                    menuActive: 'Search',
                    isSearchActive: true,
                  });
                  handleSearchSuggest(e);
                }}
                value={formSearch}
                onKeyDown={handleKeyDown}
              />
              {menu.isSearchActive && (
                <div
                  onClick={() => {
                    setMenu({
                      ...menu,
                      isOpen: true,
                      menuActive: 'SearchOptions',
                      isSearchActive: true,
                    });
                  }}
                  className="input-group-append text-primary"
                  style={{
                    padding: '8px 16px',
                    textAlign: 'center',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '24px',
                    letterSpacing: '0.28px',
                    textTransform: 'capitalize',
                    backgroundColor: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: '25%',
                    justifyContent: 'space-between',
                  }}
                >
                  {currentSearchOptions === 'Semua'
                    ? labelSemuaFilterSearch
                    : currentSearchOptions}
                  {menu.menuActive === 'SearchOptions' && (
                    <FaChevronUp
                      fontSize="16px"
                      className="text-primary ml-2"
                    />
                  )}
                  {menu.menuActive !== 'SearchOptions' && (
                    <FaChevronDown
                      fontSize="16px"
                      className="text-primary ml-2"
                    />
                  )}
                </div>
              )}

              <button
                className={`btn btn-search  py-2 px-3 ${
                  menu.isSearchActive ? 'active' : ''
                }`}
                type="button"
                onClick={handleSubmit}
              >
                <BsSearch fontSize="16px" className="text-white" />{' '}
              </button>
            </div>
            <div className="menu-section flex-shrink-0">
              <nav className={`menu-nav ${menu.isSearchActive ? '' : ''}`}>
                <div
                  className="d-flex align-items-center "
                  onMouseEnter={() =>
                    setMenu({
                      ...menu,
                      isOpen: true,
                      menuActive: 'Pro',
                    })
                  }
                >
                  <div
                    className={`item ${
                      menu.menuActive == 'Pro' ? ' active' : ''
                    }`}
                  >
                    <span className="body font-weight-semibold text-white mr-1">
                      Pro
                    </span>
                    <BsFillStarFill
                      fontSize="12px"
                      className="text-secondary"
                    />{' '}
                    <BsChevronDown
                      fontSize="14px"
                      className="text-white ml-1"
                    />{' '}
                  </div>
                </div>
                <div
                  className="d-flex align-items-center"
                  onMouseEnter={() =>
                    setMenu({
                      ...menu,
                      isOpen: true,
                      menuActive: 'Solusi',
                    })
                  }
                >
                  <div
                    className={`item ${
                      menu.menuActive === 'Solusi' ? ' active' : ''
                    }`}
                  >
                    <span className="body font-weight-semibold text-white mr-1">
                      Solusi
                    </span>
                    <BsChevronDown
                      fontSize="14px"
                      className="text-white ml-1"
                    />{' '}
                  </div>
                </div>
                <div
                  className="d-flex align-items-center"
                  onMouseEnter={() =>
                    setMenu({
                      ...menu,
                      isOpen: true,
                      menuActive: 'Wawasan Hukum',
                    })
                  }
                >
                  <div
                    className={`item ${
                      menu.menuActive === 'Wawasan Hukum' ? ' active' : ''
                    }`}
                  >
                    <span className="body font-weight-semibold text-white mr-1">
                      Info Hukum
                    </span>
                    <BsChevronDown
                      fontSize="14px"
                      className="text-white ml-1"
                    />{' '}
                  </div>
                </div>
                <div
                  className="d-flex align-items-center"
                  onMouseEnter={() =>
                    setMenu({
                      ...menu,
                      isOpen: true,
                      menuActive: 'Event',
                    })
                  }
                >
                  <div
                    className={`item ${
                      menu.menuActive === 'Event' ? ' active' : ''
                    }`}
                  >
                    <span className="body font-weight-semibold text-white mr-1">
                      Event & Awards
                    </span>
                    <BsChevronDown
                      fontSize="14px"
                      className="text-white ml-1"
                    />{' '}
                  </div>
                </div>
              </nav>
            </div>
            {menu.isOpen && menu.isSearchActive && (
              <div
                onMouseLeave={() => {
                  searchInput.current.blur();
                  setMenu({
                    ...menu,
                    isOpen: false,
                    menuActive: '',
                    isSearchActive: false,
                  });
                }}
                className={`position-absolute mega-menu_n ${
                  menu.isSearchActive ? 'expand' : ''
                } p-4 ${menu.menuActive === 'Wawasan Hukum' ? '' : ''} ${
                  menu.menuActive === 'Search' ? ' search' : ''
                } ${
                  menu.menuActive === 'SearchSuggest' &&
                  menu.isSearchActive &&
                  searchSuggestions.length === 0
                    ? ' hidden'
                    : ' suggest'
                }`}
              >
                {menu.menuActive === 'Search' && (
                  <DesktopSearch
                    popularSearch={props.popularSearch}
                    userDetail={props.userDetail}
                  />
                )}
                {menu.menuActive === 'SearchSuggest' && (
                  <DesktopSearchSuggest
                    query={formSearch}
                    searchOption={currentSearchOptions}
                    userDetail={props.userDetail}
                    handleSubmit={handleSubmit}
                  />
                )}

                {menu.menuActive === 'SearchOptions' && (
                  <div className="position-absolute search-options">
                    <div style={{ width: '100%' }}>
                      <div className="pr-0">
                        {searchOption.map((item, index) => (
                          <div
                            className={`search-options-detail ${
                              currentSearchOptions === item ? 'active' : ''
                            }`}
                            key={`search-options-${item}`}
                            onClick={() => {
                              setMenu({
                                ...menu,
                                isOpen: false,
                                menuActive: '',
                                isSearchActive: true,
                              });
                              setCurrentSearchOptions(item);
                            }}
                            tabIndex={0} // Add tabIndex attribute
                            role="button" // Add role attribute
                          >
                            <div>
                              {item === 'Semua' ? labelSemuaFilterSearch : item}
                            </div>
                            {currentSearchOptions === item && (
                              <div>
                                <AiOutlineCheck
                                  fontSize="16px"
                                  className="text-primary"
                                  style={{ marginLeft: '50px' }}
                                />
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="d-flex flex-shrink-0 align-items-center">
            <ProfileComponent {...props} />
          </div>

          <div
            onMouseLeave={() => {
              setMenu({
                ...menu,
                isOpen: false,
                menuActive: '',
                isSearchActive: false,
              });
            }}
            className={`position-absolute mega-menu_n p-4 dropdown-navbar ${
              menu.isSearchActive ? 'expand' : ''
            } ${menu.menuActive === 'Wawasan Hukum' ? '' : ''} ${
              menu.menuActive === 'Search' ? 'search' : ''
            } ${menu.menuActive === 'Wawasan Hukum' ? 'd-block' : 'd-none'}`}
          >
            <DesktopWawasan t={props.t} />
          </div>
          <div
            onMouseLeave={() => {
              setMenu({
                ...menu,
                isOpen: false,
                menuActive: '',
                isSearchActive: false,
              });
            }}
            className={`position-absolute mega-menu_n p-4 dropdown-navbar ${
              menu.isSearchActive ? 'expand' : ''
            } ${menu.menuActive === 'Wawasan Hukum' ? '' : ''} ${
              menu.menuActive === 'Search' ? 'search' : ''
            } ${menu.menuActive === 'Pro' ? 'd-block' : 'd-none'}`}
          >
            <DesktopPro t={props.t} />
          </div>
          <div
            onMouseLeave={() => {
              setMenu({
                ...menu,
                isOpen: false,
                menuActive: '',
                isSearchActive: false,
              });
            }}
            className={`position-absolute mega-menu_n p-4 dropdown-navbar ${
              menu.isSearchActive ? 'expand' : ''
            } ${menu.menuActive === 'Wawasan Hukum' ? '' : ''} ${
              menu.menuActive === 'Search' ? 'search' : ''
            } ${menu.menuActive === 'Solusi' ? 'd-block' : 'd-none'}`}
          >
            <DesktopSolusi t={props.t} />
          </div>
          <div
            onMouseLeave={() => {
              setMenu({
                ...menu,
                isOpen: false,
                menuActive: '',
                isSearchActive: false,
              });
            }}
            className={`position-absolute mega-menu_n p-4 dropdown-navbar ${
              menu.isSearchActive ? 'expand' : ''
            } ${menu.menuActive === 'Wawasan Hukum' ? '' : ''} ${
              menu.menuActive === 'Search' ? 'search' : ''
            } ${menu.menuActive === 'Event' ? 'd-block' : 'd-none'}`}
          >
            <DesktopEvent t={props.t} />
          </div>
        </div>

        {/* <div className="overlay-bg" /> */}
      </Container>
    </>
  );
});
export default MenuDesktop;
